import React from 'react';
import Image1 from '../../../styles/images/face.jpg';
import Title from '../../Title/Title';
import { aboutList } from '../../../helper/questionsTextConfig';

const About = () => {
  return (
    <section class="about-area ptb--10" id="about">
      <div class="container">
        <Title titleName='About' />
        <div class="about-content">
          <p>
            Мати гладку шовковисту шкіру&nbsp;&mdash; це невід'ємне бажання будь-якої жінки.
            Для досягнення цієї мети використовують багато різних способів. Найпоширеніший з них це епіляція
            воском і цукровою пастою. Ще їх називають ваксинг і шугаринг.
          </p>
          <p>
            В наш час і з нашим ритмом життя ці способи знають майже всі. Вони стали настільки популярні, що
            людей, які не спробували залишилося дуже мало. Багато хто знає і переконаний, що це найгуманніший,
            лагідний і природний вид епіляції. Ви скажете &laquo;Так це&nbsp;ж боляче!&raquo; Хочу вас переконати в зворотному.
            Якщо ви потрапили в руки до досвідченого кваліфікованого майстра, який знає свою справу, то вам нічого
            боятися. Досвідчений майстер завжди підбере правильну техніку, необхідний матеріал і працюватиме
            в ритмі, в якому буде зручно клієнту, а больові відчуття наближені до мінімуму або навіть до
            нулю.
          </p>
          <p>
            Дуже важливо знайти &laquo;свого&raquo; майстра, &laquo;свою&raquo; студію. А тим більше в такому достатньо
            інтимному процесі, як шугаринг. Ще не знайшли?
          </p>
        </div>
        <div class="row" style={{ 'justifyContent': 'space-between' }}>
          <div class="col-md-4 hidden-sm hidden-xs">
            <div class="about-left-thumb">
              <img src={Image1} alt="mobile screen" />
            </div>
          </div>
          <div class="about_content col-md-offset-1 col-sm-12 col-xs-12">
            <div class="about-content">
              <h4 className='about-content__title'>Тоді давайте знайомитись</h4>
              <p>
                Мене звати Катерина. Я&nbsp;&mdash; сертифікований майстер воскової та цукрової епіляції.
                Працюю з задоволенням і запрошую всіх дівчаток до себе на процедуру краси. Я
                дуже люблю свою роботу і люблю робити шкіру гладенькою. Адже саме після процедури
                краси ми стаємо впевненішими в собі і любимо себе ще більше. До всіх своїх
                гостей, які приходять до мене я ставлюся з особливою турботою і уважністю.
                Я теж дівчинка і знаю як має все статися, щоб було зручно і спокійно.
                Тому намагаюся щоб:
              </p>
              {aboutList && (
                <div className="question-ft">
                  <ol>
                    {aboutList.map(item => (
                      <li key={item.id}>
                        <p style={{ 'margin': '0px' }}>
                          {item.text}
                        </p>
                      </li>
                    ))}
                  </ol>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
